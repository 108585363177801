import React from "react"
import {connect} from "react-redux"

// Models
import {AppState} from "../store/models"
import {UserInterface} from "../modules/authorization/models"

// Selectors
import {userSelector} from "../modules/authorization/store/selectors"

export interface WithUserProps {
    user: UserInterface | null
}

export default function withUser(BaseComponent: React.ComponentType): React.ComponentType {
    return connect((state: AppState) => userSelector(state), null)(BaseComponent);
}
