import React from "react"
import {Dispatch} from "redux"
import {connect} from "react-redux"

// Actions
import * as a from "../store/actions"

// Models
import {AppState} from "../../../store/models"

// Selectors
import {selectSidebarIsClosed} from "../store/selectors"

export type WithSidebarToggleProps = {
    sidebarClosed: boolean
    toggleSidebar(): void
}

export default function withSidebarToggle(BaseComponent: React.ComponentType<any>) {
    return connect(
        (state: AppState) => ({
            sidebarClosed: selectSidebarIsClosed(state),
        }),
        (dispatch: Dispatch) => ({
            toggleSidebar: () => dispatch(a.toggleSidebar()),
        }),
    )(BaseComponent)
}
