import {StoreKeys} from "../../../../store/constants"

// Models
import {CommonStateType, CountryPhoneCodeWithKeyType} from "../../models"
import {AppState} from "../../../../store/models"

const getCommonStateSlice = (state: AppState): CommonStateType => state[StoreKeys.COMMON];

/**
 * @param state
 */
export const selectCountryCodesList = (state: AppState): Array<CountryPhoneCodeWithKeyType> => {
    const {countryCodes} = getCommonStateSlice(state);

    return Object.keys(countryCodes).map(countryCode => ({
        ...countryCodes[countryCode],
        key: countryCode,
    }))
};

/**
 * @param state
 */
export const selectCountryCodesLength = (state: AppState) => {
    const {countryCodes} = getCommonStateSlice(state);

    return Object.keys(countryCodes).length
}

/**
 * @param state
 * @param countryCode
 */
export const selectPhoneCodeByCountryCode = (state: AppState, countryCode: string) => {
    const {countryCodes} = getCommonStateSlice(state);

    if (typeof countryCodes[countryCode] === "undefined") {
        return ""
    }

    return countryCodes[countryCode].phone
};

/**
 * @param state
 */
export const selectSidebarIsClosed = (state: AppState) => getCommonStateSlice(state).sidebarClosed;

/**
 * @param state
 */
export const selectCountryCodesLoading = (state: AppState) => getCommonStateSlice(state).countryCodesLoading;

/**
 * @param state
 */
export const selectSettingsModalVisible = (state: AppState) => getCommonStateSlice(state).settingsModalVisible;